import store from "../redux/store/store";
import { HideLoader, ShowLoader } from "../redux/state-slice/settings-slice";
import axios from "axios";
import { ErrorToast, SuccessToast } from "../helper/FormHelper";
import { getToken } from "../helper/SessionHelper";
import { SetCategoryList, SetCategoryListTotal, ResetCategoryFormValue, OnChangeCategoryInput } from "../redux/state-slice/category-slice";
import { BaseURL } from "../helper/config";
const AxiosHeader = { headers: { "token": getToken() } }

export async function CategoryListRequest(pageNo, perPage, searchKeyword) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/CategoriesList/" + pageNo + "/" + perPage + "/" + searchKeyword;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "success") {
            if (result.data['data'][0]['Rows'].length > 0) {
                store.dispatch(SetCategoryList(result.data['data'][0]['Rows']))
                store.dispatch(SetCategoryListTotal(result.data['data'][0]['Total'][0]['count']))
            } else {
                store.dispatch(SetCategoryList([]))
                store.dispatch(SetCategoryListTotal(0))
                ErrorToast("No Data Found")
            }
        } else {
            ErrorToast("Something Went Wrong")
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
    }
}

export async function CreateCategoryRequest(PostBody, ObjectID) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/CreateCategories"
        if (ObjectID !== 0) {
            URL = BaseURL + "/UpdateCategories/" + ObjectID;
        }
        const result = await axios.post(URL, PostBody, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "success") {
            SuccessToast("Request Successful");
            store.dispatch(ResetCategoryFormValue())
            return true;
        }
        else if (result.status === 200 && result.data['status'] === "fail") {
            if (result.data['data']['keyPattern']['Name'] === 1) {
                ErrorToast("Category Already Exist")
                return false;
            }
        }
        else {
            ErrorToast("Request Fail ! Try Again")
            return false;
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
        return false
    }
}




export async function FillCategoryFormRequest(ObjectID) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/CategoriesDetailsByID/" + ObjectID;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "success") {
            let FormValue = result.data['data'][0];
            store.dispatch(OnChangeCategoryInput({ Name: "Name", Value: FormValue['Name'] }));
            return true;
        } else {
            ErrorToast("Request Fail ! Try Again")
            return false;
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
        return false
    }
}



export async function DeleteCategoryRequest(ObjectID) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/DeleteCategories/" + ObjectID;
        let result = await axios.delete(URL, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "associate") {
            ErrorToast(result.data['data'])
            return false;
        }
        if (result.status === 200 && result.data['status'] === "success") {
            SuccessToast("Request Successful");
            return true
        }
        else {
            ErrorToast("Request Fail ! Try Again")
            return false;
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
        return false
    }
}


