import store from "../redux/store/store";
import { HideLoader, ShowLoader } from "../redux/state-slice/settings-slice";
import axios from "axios";
import { ErrorToast, SuccessToast } from "../helper/FormHelper";
import { getToken } from "../helper/SessionHelper";
import { SetBrandList, SetBrandListTotal, ResetBrandFormValue, OnChangeBrandInput } from "../redux/state-slice/brand-slice";
import { BaseURL } from "../helper/config";
const AxiosHeader = { headers: { "token": getToken() } }

export async function BrandListRequest(pageNo, perPage, searchKeyword) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/BrandList/" + pageNo + "/" + perPage + "/" + searchKeyword;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "success") {
            if (result.data['data'][0]['Rows'].length > 0) {
                store.dispatch(SetBrandList(result.data['data'][0]['Rows']))
                store.dispatch(SetBrandListTotal(result.data['data'][0]['Total'][0]['count']))
            } else {
                store.dispatch(SetBrandList([]))
                store.dispatch(SetBrandListTotal(0))
                ErrorToast("No Data Found")
            }
        } else {
            ErrorToast("Something Went Wrong")
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
    }
}


export async function CreateBrandRequest(PostBody, ObjectID) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/CreateBrand"
        if (ObjectID !== 0) {
            URL = BaseURL + "/UpdateBrand/" + ObjectID;
        }
        const result = await axios.post(URL, PostBody, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "success") {
            SuccessToast("Request Successful");
            store.dispatch(ResetBrandFormValue())
            return true;
        }
        else if (result.status === 200 && result.data['status'] === "fail") {
            if (result.data['data']['keyPattern']['Name'] === 1) {
                ErrorToast("Brand Name Already Exist")
                return false;
            }
        }
        else {
            ErrorToast("Request Fail ! Try Again")
            return false;
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
        return false
    }
}

export async function FillBrandFormRequest(ObjectID) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/BrandDetailsByID/" + ObjectID;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "success") {
            let FormValue = result.data['data'][0];
            store.dispatch(OnChangeBrandInput({ Name: "Name", Value: FormValue['Name'] }));
            return true;
        } else {

            ErrorToast("Request Fail ! Try Again")
            return false;
        }
    }
    catch (e) {

        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
        return false
    }
}

export async function DeleteBrandRequest(ObjectID) {
    try {
        store.dispatch(ShowLoader())
        let URL = BaseURL + "/DeleteBrand/" + ObjectID;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(HideLoader())
        if (result.status === 200 && result.data['status'] === "associate") {
            ErrorToast(result.data['data'])
            return false;
        }
        if (result.status === 200 && result.data['status'] === "success") {
            SuccessToast("Request Successful");
            return true
        }
        else {
            ErrorToast("Request Fail ! Try Again")
            return false;
        }
    }
    catch (e) {
        ErrorToast("Something Went Wrong")
        store.dispatch(HideLoader())
        return false
    }
}

